import React from "react";
import "../Styles/aboutus.css";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const AboutUs = () => {
  return (
    <div>
   <div
    className="back-ground-img mb-4"
    style={{
      backgroundImage: 'url("/Images/back-aboutus.jpg")',
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "40vh",
      width: "100%",
      position: "relative", // Set relative positioning for overlay
    }}
  >
    <div className="overlay"></div>
    <div className="text-content">
      About Us
    </div>
  </div>
      <Container>
        <div className="row about-us-head">
          <h2 className="about-us-in" style={{color:"blue"}}>About Us</h2>
        </div>
        <div className="row mt-4 mb-4">
          <p className="about-us-para-2">
            Nestled in the heart of the KIADB Hitech, Defense, and Aerospace
            Park, our startup is poised to redefine the landscape of Electronics
            Manufacturing Services (EMS). As a dynamic and forward-thinking
            company, we specialize in providing innovative and high-quality
            solutions tailored to meet the unique demands of industries such as
            defense, aerospace, automotive, and consumer electronics.
          </p>
          <p className="about-us-para-2">
            Our advanced Surface Mount Technology (SMT) line is the backbone of
            our operations, equipped with state-of-the-art machinery to deliver
            precision and efficiency at every stage of the manufacturing
            process. With versatile pick-and-place machines capable of handling
            a diverse range of components and board designs, an advanced stencil
            printer for accurate solder paste application, a 10-zone reflow oven
            for optimal soldering conditions, and 3D Solder Paste Inspection
            (SPI) and Automated Optical Inspection (AOI) systems, we ensure
            exceptional quality and reliability in every product we manufacture.
          </p>
          <p className="about-us-para-2">
            Driven by a passionate team of skilled professionals, we are
            committed to providing end-to-end solutions that encompass
            everything from design and prototyping to production, testing, and
            supply chain management. Our agile and innovative approach enables
            us to adapt quickly to the ever-changing needs of our clients,
            delivering products that exceed expectations.
          </p>
          <p className="about-us-para-2">
            As a young and ambitious startup, we embrace the latest technologies
            and methodologies to push the boundaries of what is possible in
            electronics manufacturing. With a strong foundation, a commitment to
            quality, and a customer-centric focus, we are ready to be your
            trusted partner in bringing your electronics ideas to life.
          </p>
        </div>
      </Container>
      <Container>
        <div className="row our-v-m-head">
            <h2 className="our-v-m">
                OUR VISION & MISSION
            </h2>
        </div>
        <div className="row mb-4">
          <div className="col-md-5">
            <img
              src="../Images/ourvision.jpg"
              alt=""
              style={{ height: "250px", width: "400px" }}
              className="mt-4 our-vision-2"
            />
          </div>
          <div className="col-md-6">
            <div className="row our-vision-head">
              <Card style={{ width: "40rem" }} className="card-our-vision mt-4">
                <Card.Body>
                  <Card.Title className="our-vision-title">OUR VISION</Card.Title>
                  <Card.Text className="our-vision-para-3">
                    Our vision for RK Industries is to be a Reliable, Quality,
                    Medium Volume Manufacturer, Exporter, “Pleasing Customers
                    100 %” in the Complex High Count Multilayer Printed Circuit
                    Boards.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="row our-vision-head">
              <Card style={{ width: "40rem" }} className="card-our-vision mt-4">
                <Card.Body>
                  <Card.Title className="our-vision-title">OUR MISSION</Card.Title>
                  <Card.Text className="our-vision-para-3">
                  Our Mission for RK Industries is to be a Performance Driven, Reliable, Continuously Improving, High Quality, Medium Volume independent manufacturer, exporter” Pleasing customer 100%” in the complex High Count Multilayer PCB. To have a manufacturing base in each of the major market served by the company.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="col-md-5">
            <img
              src="../Images/ourmission.jpg"
              alt=""
              style={{ height: "250px", width: "400px" }}
              className="mt-4 our-vision-2"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
