import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import "../Styles/home.css";
import Card from "react-bootstrap/Card";
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate=useNavigate();   

    const handleClick = () => {
        navigate('/about-us'); // Navigate to the About Us page
      };
  return (
    <div>
        <Container>
        <div className="row mt-4 mb-4">
          <p className="about-us-para-2">
            Nestled in the heart of the KIADB Hitech, Defense, and Aerospace
            Park, our startup is poised to redefine the landscape of Electronics
            Manufacturing Services (EMS). As a dynamic and forward-thinking
            company, we specialize in providing innovative and high-quality
            solutions tailored to meet the unique demands of industries such as
            defense, aerospace, automotive, and consumer electronics.
          </p>
          <p className="about-us-para-2">
            Our advanced Surface Mount Technology (SMT) line is the backbone of
            our operations, equipped with state-of-the-art machinery to deliver
            precision and efficiency at every stage of the manufacturing
            process. With versatile pick-and-place machines capable of handling
            a diverse range of components and board designs, an advanced stencil
            printer for accurate solder paste application, a 10-zone reflow oven
            for optimal soldering conditions, and 3D Solder Paste Inspection
            (SPI) and Automated Optical Inspection (AOI) systems, we ensure
            exceptional quality and reliability in every product we manufacture.
          </p>
          <p className="about-us-para-2">
            Driven by a passionate team of skilled professionals, we are
            committed to providing end-to-end solutions that encompass
            everything from design and prototyping to production, testing, and
            supply chain management. Our agile and innovative approach enables
            us to adapt quickly to the ever-changing needs of our clients,
            delivering products that exceed expectations.
          </p>
          <p className="about-us-para-2">
            As a young and ambitious startup, we embrace the latest technologies
            and methodologies to push the boundaries of what is possible in
            electronics manufacturing. With a strong foundation, a commitment to
            quality, and a customer-centric focus, we are ready to be your
            trusted partner in bringing your electronics ideas to life.
          </p>
        </div>
        </Container>
      
        {/* <Carousel fade>
          <Carousel.Item>
            <img src="../Images/pcb2.jpg" alt="" height={600} width="100%" className="cor-mob-res"/>
            <Carousel.Caption>
              <h3 className="pos-abs-head">
                Electronic Manufacturing Services
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src="../Images/pcb1.jpg" alt="" height={600} width="100%" className="cor-mob-res" />
            <Carousel.Caption>
              <h3 className="pos-abs-head">PCB ASSEMBLING</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src="../Images/pcb3.jpg" alt="" height={600} width="100%" className="cor-mob-res"/>
            <Carousel.Caption>
              <h3 className="pos-abs-head">PCB Prototyping</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
      
      {/* <Container>
        <div className="row mt-4 mb-4">
          <div className="col-md-6">
            <img
              src="../Images/aboutus.jpg"
              alt=""
              style={{ width: "400px", height: "300px" }}
              className="about-in-home-mr"
            />
          </div>
          <div className="col-md-6">
            <div className="row welcome-text mt-4">
              <h1 className="heading-design">Welcome to Rk Industries</h1>
            </div>
            <div className="row">
              <p className="para-in-home">
                Complete solutions for your PCB needs. RK Industries is
                committed to manufacturing and delivering defect-free PCBs to
                manufacturing industries by continually upgrading its services,
                process capabilities and product range.
              </p>
            </div>
            <div className="row">
              <button className="view-more-btn" onClick={handleClick}>View more</button>
            </div>
          </div>
        </div>
      </Container> */}
      {/* <Container>
        <div className="row why-choose-us">
            <h2>
            Why Choose Us</h2>
            </div>
        <div className="row mt-4 mb-4">
          <div className="col-md-4 mt-2">
            <Card style={{ width: "18rem" }} className="card-h-w">
              <Card.Body>
                <Card.Title style={{color:"blue",fontFamily:"serif"}}>QUALITY ASSURANCE</Card.Title>
                <Card.Text style={{padding:"15px",fontFamily:"serif",fontSize:"17px"}}>
                  Our process is standardized and we have the tools in place to
                  ensure quality, consistency, and safety.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4 mt-2">
            <Card style={{ width: "18rem" }} className="card-h-w">
              <Card.Body>
                <Card.Title style={{color:"blue",fontFamily:"serif"}}>CUSTOMIZABLE SERVICE</Card.Title>
                <Card.Text style={{padding:"15px",fontFamily:"serif",fontSize:"17px"}}>
                  Our services molded as per your needs and desires in a smart
                  and intelligent manner.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4 mt-2">
          <Card style={{ width: "18rem" }} className="card-h-w">
              <Card.Body>
                <Card.Title style={{color:"blue",fontFamily:"serif"}}>HIGH RELIABILITY</Card.Title>
                <Card.Text style={{padding:"15px",fontFamily:"serif",fontSize:"17px"}}>
                Quality and safety are our main focus to eliminate any risk and failures.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container> */}
    </div>
  );
};

export default Home;
