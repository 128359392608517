import React from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";

const Footer = () => {
  return (
    <div>
      <footer style={{ backgroundColor: "blue" }}>
        <div className="row">
          <div
            className="col-md-4"
            style={{ color: "white", fontWeight: "700" }}
          >
            <p className="fs-3"> RK Industries </p>
          </div>
          <div
            className="col-md-4 "
            style={{ color: "white", fontWeight: "700" }}
          >
            <div className="con-head fs-3">Contact Us</div>
            <div className="d-flex gap-3 justify-content-center fs-5 mt-3">
              <p>
                <FaPhoneVolume />
              </p>
              <p>+91 7829081111</p>
            </div>
            <div
              className="d-flex gap-3 justify-content-center fs-5"
              style={{ marginLeft: "30px" }}
            >
              <p>
                <TfiEmail />
              </p>
              <p>info@rkindustries.io</p>
            </div>
          </div>
          <div
            className="col-md-4 mt-2 mb-2 foot-map-google-loc "
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.1256591676633!2d77.6907846!3d13.1544728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1b703f0a0e23%3A0x693d1686b24350d3!2sR%20K%20Industries!5e0!3m2!1sen!2sin!4v1725946646475!5m2!1sen!2sin"
              width="300px"
              height="200px"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
