import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<>
          <Header/>
            <Home /> 
            <Footer/> </>} />
                {/* <Route path="/about-us" element={
                  <>
                  <Header/>
                  <AboutUs/>
                  <Footer/> </>} /> */}
          <Route path="/contact-us" element={
            <>
            <Header/>
            <ContactUs/>
            <Footer/> </>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
