import React from 'react'
import '../Styles/contactus.css';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { FaPhoneVolume } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlLocationPin } from "react-icons/sl";

const ContactUs = () => {
  return (
    <div>
         <div
  className="back-ground-img-contact mb-4"
  style={{
    backgroundImage: 'url("/Images/contactUs.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "40vh",
    width: "100%",
  }}
>
  <div className="overlay"></div>
  <div className="text-content">
    Contact Us
  </div>
</div>

<Container>
<div className="row mt-5 mb-5">
    <div className="col-md-4 mt-2">
    <Card style={{height:"120px", width:"300px"}} className='mob-res-card-con'>
      <Card.Body>
        <div className="d-flex gap-4 justify-content-center">
            <p className='fs-3' style={{color:"blue"}}><FaPhoneVolume/></p>
            <p className='fs-5 card-content' style={{fontWeight:"500",paddingTop:"8px",fontFamily:"serif"}}>+91 7829081111</p>
        </div>
      </Card.Body>
    </Card>
    </div>
    <div className="col-md-4 mt-2">
    <Card style={{height:"120px", width:"300px"}} className='mob-res-card-con'>
      <Card.Body>
      <div className="d-flex gap-3 justify-content-center">
            <p className='fs-3' style={{color:"blue"}}><TfiEmail/></p>
            <p className='fs-5 card-content' style={{fontWeight:"500",paddingTop:"8px",fontFamily:"serif"}}>info@rkindustries.io</p>
        </div>
      </Card.Body>
    </Card>
    </div>
    <div className="col-md-4 mt-2">
    <Card style={{height:"120px", width:"300px"}} className='mob-res-card-con'>
      <Card.Body>
      <div className="d-flex gap-3 justify-content-center">
            <p className='fs-3' style={{color:"blue"}}><SlLocationPin/></p>
            <p className='fs-5 card-content' style={{fontWeight:"500",paddingTop:"8px",fontFamily:"serif"}} > Huvinayakanahalli, Bengaluru, Karnataka 562149</p>
        </div>
        </Card.Body>
    </Card>
    </div>
</div>
</Container>
<Container>
<div className="row mt-4 mb-4   foot-map-mob-res">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.1256591676633!2d77.6907846!3d13.1544728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1b703f0a0e23%3A0x693d1686b24350d3!2sR%20K%20Industries!5e0!3m2!1sen!2sin!4v1725946646475!5m2!1sen!2sin"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Location Map"
        width="100%"
      ></iframe>
    </div>
</Container>
    </div>
  )
}

export default ContactUs