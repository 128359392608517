import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../Styles/header.css';

const Header = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary mb-3" st>
      <Container fluid>
        {/* Logo as text */}
        <Navbar.Brand href="#" className="fw-bold fs-4 text-primary logo-text">
          RK Industries
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"                                                                                                        
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel fw-bold fs-4 text-primary logo-text">
            RK Industries
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">Home</Nav.Link>
              {/* <Nav.Link href="/about-us">About Us</Nav.Link> */}
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>

              {/* You can add more links or other content here */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
